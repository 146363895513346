import React from 'react'

const NotfoundPage = () => {

    return (
        <div>
        </div>
    )
}

export default NotfoundPage